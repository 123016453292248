/**
 * UtilitiesBase class.
 * 
 * @module webComponents/build/utilitiesBase
 */

/**
 * 
 */
export default class UtilitiesBase {
	/**
	 * Set the title of the API page.
	 * 
	 * @param [string=""]
	 */
	static setTitle(string = "") {
		window.document.title = string !== "" ? string.charAt(0).toUpperCase()+string.substring(1) : "";
	}
	
	/**
	 * Set the description of the API page.
	 * 
	 * @param [string=""]
	 */
	static setDescription(string = "") {
		string = string.substring(0,Math.min(string.length,1000));
		let description = window.document.querySelector('meta[name="description"]');
		if(description === null) {
			description = window.document.createElement("meta");
			description.name = "description";
			window.document.head.appendChild(description);
		}
		description.content = string;
	}
	
	/**
	 * Set robots meta tag noindex instruction
	 */
	static setNoindex() {
		let robots = window.document.querySelector('meta[name="robots"]');
		if(robots === null) {
			robots = window.document.createElement("meta");
			robots.name = "robots";
			window.document.head.appendChild(robots);
		}
		const instructions = robots.content.length > 0 ? robots.content.split(",") : [];
		if(!instructions.includes("noindex")) {
			instructions.push("noindex");
		}
		robots.content = instructions.join(",");
	}
	
	/**
	 * Unset robots meta tag noindex instruction
	 */
	static unsetNoindex() {
		let robots = window.document.querySelector('meta[name="robots"]');
		if(robots === null) {
			robots = window.document.createElement("meta");
			robots.name = "robots";
			window.document.head.appendChild(robots);
		}
		const instructions = robots.content.length > 0 ? robots.content.split(",") : [];
		const tmp = [];
		for(const e of instructions) {
			if(e !== "noindex") {
				tmp.push(e);
			}
		}
		robots.content = tmp.join(",");
	}
	
	/**
	 * Remove all children of an HTMLElement and eventually render is invisible.
	 * 
	 * @param {HTMLElement} element
	 * @param {boolean} [withDisplay=false]
	 */
	static resetElement(element,withDisplay = false) {
		while(element.lastChild !== null) {
			element.lastChild.remove();
		}
		if(withDisplay) {
			element.classList.add("display-none");
		}
	}
	
	/**
	 * Trigger download of file.
	 * 
	 * @param {string} content
	 * @param {string} filename
	 */
	static download(content,filename) {
		const url = URL.createObjectURL(content);
		const a = window.document.createElement("a");
		a.href = url;
		a.download = filename;
		a.click();
		URL.revokeObjectURL(url);
	}
	
	/**
	 * Build Paypal button.
	 * 
	 * @param {HTMLElement} element
	 * @param {object} build
	 * @param {string} [clientId="sb"]
	 * @param {ErrorHandler} [errorHandler=null]
	 */
	static async buildPaypal(element,build,clientId = "sb",errorHandler = null) {
		if(!window.hasOwnProperty("paypal")) {
			await this.setScript("https://www.paypal.com/sdk/js?client-id="+clientId+"&currency=EUR&vault=true&intent=subscription&disable-funding=credit",new Map([
				["variable","paypal"],
				["withSrc",true]
			]),errorHandler);
		}
		window.paypal.Buttons(build).render(element);
	}
	
	/**
	 * Append style to element.
	 * 
	 * @param {HTMLElement} element
	 * @param {string} resource
	 * @param {ErrorHandler} [errorHandler=null]
	 * @return .
	 */
	static async setStyle(element,resource,errorHandler = null) {
		const res = await this.getStyle(resource,errorHandler);
		const style = window.document.createElement("style");
		style.type = "text/css";
		style.innerHTML = res;
		if(element.shadowRoot !== null) {
			element.shadowRoot.appendChild(style);
		} else {
			element.appendChild(style);
		}
		return res;
	}
	
	/**
	 * Get a style resource.
	 * 
	 * @param {string} resource
	 * @param {ErrorHandler} [errorHandler=null]
	 * @return .
	 */
	static async getStyle(resource,errorHandler = null) {
		return await this.getResource(resource,errorHandler);
	}
	
	/**
	 * Build a script.
	 * 
	 * @param {string} resource
	 * @param {Map} [params=new Map()]
	 * @param {ErrorHandler} [errorHandler=null]
	 */
	static async setScript(resource,params = new Map(),errorHandler = null) {
		if(!params.has("variable") || !window.hasOwnProperty(params.get("variable"))) {
			if(params.has("withSrc") && params.get("withSrc")) {
				const script = window.document.createElement("script");
				script.src = resource;
				if(params.has("text")) {
					script.innerHTML = params.get("text");
				}
				window.document.body.appendChild(script);
				const tmp = async () => {
					if(params.has("variable") && !window.hasOwnProperty(params.get("variable"))) {
						await new Promise((resolve,reject) => {
							window.setTimeout(resolve,500);
					    });
				        await tmp();
					}
				};
				await tmp();
			} else {
				const res = await this.getScript(resource,errorHandler);
				if(params.has("variable") && !window.hasOwnProperty(params.get("variable"))) {
					const script = window.document.createElement("script");
					script.innerHTML = res;
					window.document.body.appendChild(script);
				}
			}
		}
	}
	
	/**
	 * Get a script.
	 * 
	 * @param {string} resource
	 * @param {ErrorHandler} [errorHandler=null]
	 * @return .
	 */
	static async getScript(resource,errorHandler = null) {
		return await this.getResource(resource,errorHandler);
	}
	
	/**
	 * Get a resource.
	 * 
	 * @param {string} resource
	 * @param {ErrorHandler} [errorHandler=null]
	 * @return .
	 */
	static async getResource(resource,errorHandler = null) {
		try {
			const headers = new Headers();
			const init = {
				"method": "GET",
				"headers": headers,
				"credentials": "same-origin"
			};
			const response = await window.fetch(resource,init);
			if(response.status !== 200) {
				throw new Error("");
			}
			return await response.text();
		} catch(e) {
			const errorCode = "CLIENT_ERROR_REQUEST";
			if(errorHandler !== null) {
				await errorHandler(new Map([
					["code",errorCode]
				]));
			}
			throw errorCode;
		}
	}
	
	/**
	 * Encode JSON to base64Uri.
	 * 
	 * @param {JsonData} obj
	 * @return {string} encodedData
	 */
	static jsonToBase64Uri(obj) {
		return this.stringToBase64Uri(this.jsonToString(obj));
	}
	
	/**
	 * Decode base64Uri to JSON.
	 * 
	 * @param {string} string
	 * @return {JsonData} decodedData
	 */
	static base64UriToJson(string) {
		return JSON.parse(this.base64UriToString(string),(key,value) => {
			return this.jsonReviver(key,value);
		});
	}
	
	/**
	 * Encode string to base64Uri.
	 * 
	 * @param {string} string
	 * @return {string} base64uri
	 */
	static stringToBase64Uri(string) {
		let tmp = window.btoa(String.fromCharCode(...this.utf16BetoUtf8Array(string)));
		for(const l of [["\\+","-"],["/","_"],["=",""]]) {
			tmp = tmp.replace(new RegExp(l[0],"g"),l[1]);
		}
		return tmp;
	}
	
	/**
	 * Decode string from base64Uri
	 * 
	 * @param {string} string
	 * @return {string} string
	 */
	static base64UriToString(string) {
		for(const l of [["-","+"],["_","/"]]) {
			string = string.replace(new RegExp(l[0],"g"),l[1]);
		}
		return this.utf8ArrayToUtf16Be(Array.from(window.atob(string+("=".repeat(3-(string.length+3)%4)))).map((c) => {
			return c.charCodeAt(0);
		}));
	}
	
	/**
	 * Transform utf16 big endian to utf8 array.
	 * 
	 * @param {string} string
	 * @return {Array<number>} utf8Array
	 */
	static utf16BetoUtf8Array(string) {
		const utf8 = [];
		for(const char of Array.from(string)) {
			const codePoint = char.codePointAt(0);
			if(codePoint <= 127) {
				utf8.push(codePoint);
			} else if(codePoint <= 2047) {
				utf8.push(
					192 | (codePoint >> 6),
					128 | (codePoint & 63)
				);
			} else if(codePoint <= 65535) {
				utf8.push(
					224 | (codePoint >> 12),
					128 | ((codePoint >> 6) & 63),
					128 | (codePoint & 63)
				);
			} else {
				utf8.push(
					240 | (codePoint >> 18),
					128 | ((codePoint >> 12) & 63),
					128 | ((codePoint >> 6) & 63),
					128 | (codePoint & 63)
				);
			}
		}
		return utf8;
	}
	
	/**
	 * Transform utf8 array to utf16 big endian.
	 * 
	 * @param {Array<number>} string
	 * @return {string} utf16BeString
	 */
	static utf8ArrayToUtf16Be(string) {
		const codePoints = [];
		for(let i = 0; i < string.length; ++i) {
			if(string[i] <= 127) {
				codePoints.push(string[i]);
			} else if(string[i] <= 223) {
				codePoints.push(((string[i] & 31) << 6) + (string[++i] & 63));
			} else if(string[i] <= 239) {
				codePoints.push((string[i] & 15) + ((string[++i] & 63) << 6) + (string[++i] & 63));
			} else {
				codePoints.push((string[i] & 7) + ((string[++i] & 63) << 12) + ((string[++i] & 63) << 6) + (string[++i] & 63));
			}
		}
		return String.fromCodePoint(...codePoints);
	}
	
	/**
	 * Transform JSON in string.
	 * 
	 * @param {JsonData} obj
	 * @return {string} string
	 */
	static jsonToString(obj) {
		return JSON.stringify(obj,(key,value) => {
			return this.jsonReplacer(key,value);
		});
	}
	
	/**
	 * jsonReplacer to transform JSON in string.
	 * 
	 * @param {string} key
	 * @param {JsonData} value
	 * @return {JsonData} value
	 */
	static jsonReplacer(key,value) {
		if(value instanceof Map) {
			const res = {};
			for(const [k,v] of value) {
				res[k] = v;
			}
			return res;
		}
		return value;
	}
	
	/**
	 * Transform string in JSON.
	 * 
	 * @param {string} string
	 * @return {JsonData} obj
	 */
	static stringToJson(string) {
		return JSON.parse(string,(key,value) => {
			return this.jsonReviver(key,value);
		});
	}
	
	/**
	 * jsonReviver to transform string in JSON.
	 * 
	 * @param {string} key
	 * @param {JsonData} value
	 * @return {JsonData} value
	 */
	static jsonReviver(key,value) {
		if(typeof(value) === "object" && !(value instanceof Array) && value !== null) {
			return new Map(Object.entries(value));
		}
		return value;
	}
	
	/**
	 * JSON stringify a string before encoding its single quotes.
	 * 
	 * @param {string} string
	 * @return {string} string
	 */
	static escapeJsonStringSingleQuote(string) {
		return JSON.stringify(string).replace(new RegExp("'","g"),"&apos;");
	}
	
	/**
	 * Snake case to css case.
	 * 
	 * @param {string} string
	 * @return {string} string
	 */
	static snakeToCssCase(string) {
		return string.replace(new RegExp("_","g"),"-");
	}
}