/**
 * Begin the API.
 * 
 * @module main
 */
import routing,{session,sourceUri,rootUri} from "./routing.js";
import Utilities from "./webComponents/build/utilities.js";

const modelUrl = window.location.origin+rootUri+"model";

const viewport = window.document.createElement("meta");
viewport.name = "viewport";
viewport.content = "width=device-width";
window.document.head.appendChild(viewport);

let link = window.document.createElement("link");
link.rel = "icon";
link.type = "image/png";
link.href = sourceUri+"client/images/favicon.png";
window.document.head.appendChild(link);

link = window.document.createElement("link");
link.rel = "stylesheet";
link.href = sourceUri+"client/webComponents/base/import.css";
window.document.head.appendChild(link);

window.document.documentElement.style.minHeight = window.innerHeight.toString()+"px";
window.document.documentElement.style.minWidth = window.innerWidth.toString()+"px";

/**
 * Refresh the user session.
 * 
 * @private
 */
async function sessionRefresh() {
	const response = await Utilities.sendPostJsonQuery(modelUrl+"?mode=session&type=session");
	if(response.get("response")) {
		session.clear();
		for(const [k,v] of response.get("value")) {
			session.set(k,v);
		}
	}
	window.setTimeout(async () => {
		await sessionRefresh();
	},600000);
}

/**
 * Begin the API.
 * 
 * @private
 */
async function begin() {
	const response = await Utilities.sendPostJsonQuery(modelUrl,new Map(),false);
	if(!response.get("response")) {
		throw new Error("");
	}
	for(const [k,v] of response.get("value")) {
		session.set(k,v);
	}
	window.setTimeout(async () => {
		await sessionRefresh();
	},600000);
	await routing((window.location.pathname+window.location.search+window.location.hash).substring(rootUri.length));
}

begin();